.container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects {
  text-align: justify;
  max-width: 500px;
}