.test {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  background-size: contain;
  background-position: center;
  height: 100vh;
  overflow: auto;
  width: 100%;
}

.fullName {
  color: gray;
  display: flex;
  justify-content: center;
  width: 100vw;
  font-size: 50px;
  position: relative;
  top: 50px;
}

.button {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.first {
  position: relative;
  right: 15px;
}

.suffix {
  position: relative;
  left: 15px;
}

.position {
  color: gray;
  display: flex;
  justify-content: center;
  width: 100vw;
  position: relative;
  top: 50px;
}

.aboutMe {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  min-width: 100%;
  
}

img {
  height: 50vh;
  margin: 20px;
  border-radius: 20px;
}

.description {
  padding: 6px;
  height: 31vh;
  width: 65vh;
  text-align: justify;
  border-radius: 20px;
  color: white;
  margin-top: 50px;
}

.descriptionImg {
  height: 30px;
  width: 30px;
  margin: 5px;
}

.glow-on-hover {
  width: 170px;
  height: 50px;
  border: none;
  outline: none;
  color: #e9eaeb;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)
    no-repeat center center fixed;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)
    no-repeat center center fixed;
  left: 0;
  top: 0;
  border-radius: 10px;
}
